import React, { useState } from 'react';
import * as S from './styles';
import './index.scss';

export const BeerCarousel = React.memo(() => {
  const [visible, setVisible] = useState<boolean[]>([
    true,
    false,
    false,
    false,
  ]);

  const hideAll = (e: number) => {
    const array = [false, false, false, false];
    array[e] = true;
    setVisible(array);
  };

  return (
    <S.CarouselWrapper>
      <S.Carousel
        beforeChange={() => setVisible([false, false, false, false])}
        afterChange={(e) => {
          return hideAll(e);
        }}
      >
        <S.Item>
          <S.Image src="/images/slide_04.jpg" alt="" />
        </S.Item>
        <S.Item>
          <S.Image src="./images/slide_02.jpg" alt="" />
        </S.Item>
        <S.Item>
          <S.Image src="./images/slide_03.jpg" alt="" />
        </S.Item>
        <S.Item>
          <S.Image src="/images/slide_01.jpg" alt="" />
        </S.Item>
      </S.Carousel>

      <S.HeaderLeft className={visible[0] ? 'show' : 'hide'}>
          <S.TextWrapper>IN THE HEART OF DARTMOOR</S.TextWrapper>
      </S.HeaderLeft>

      <S.DescriptionLeft className={visible[0] ? 'show' : 'hide'}>
        Located in the Dartmoor National Park and overlooking Peter Tavy,
        Tavistock Brewery is a family run business which has been 5 years in the
        planning and design.
      </S.DescriptionLeft>

      <S.HeaderRight className={visible[1] ? 'show' : 'hide'}>
          <S.TextWrapper>SMALL FAMILY BUSINESS</S.TextWrapper>
      </S.HeaderRight>

      <S.DescriptionRight className={visible[1] ? 'show' : 'hide'}>
        The idea for Tavistock Brewery grew from a love of the partners for beer
        and for brewing. The partners are father and son, and both are born and
        bred Devonians. Having lived in the Tavistock area for a number of years
        and found a need for locally produced beer, the two decided to fulfil
        this need by indulging in their passion for making beer. They are
        supported by other family members specialising in Marketing,
        Administration and Website creation. The microbrewery is situated in a
        building within the grounds of the family home in Peter Tavy,
        overlooking green valleys and sitting at the foot of Dartmoor.
      </S.DescriptionRight>

      <S.HeaderLeft className={visible[2] ? 'show' : 'hide'}>
          <S.TextWrapper>CRAFT ALE WITH PASSION</S.TextWrapper>
      </S.HeaderLeft>

      <S.DescriptionLeft className={visible[2] ? 'show' : 'hide'}>
        Tavistock Brewery has grown from a passion for brewing and gradually
        developed to enable us to perfect our craft ales which we are proud to
        supply to the local area and beyond.
      </S.DescriptionLeft>
      <S.HeaderLeft className={visible[3] ? 'show' : 'hide'}>
         <S.TextWrapper>THE BREWERY</S.TextWrapper>
      </S.HeaderLeft>
      <S.DescriptionLeft className={visible[3] ? 'show' : 'hide'}>
        Our modern micro-brewery has first class facilities which includes the
        brewery itself, an administrative centre and hospitality area for
        potential clients who may be interested in seeing how our ales are
        produced.
      </S.DescriptionLeft>
    </S.CarouselWrapper>
  );
});
